import { NextPageContext } from 'next';
import React from 'react';

import { selectArtistStateItem } from '~/app/lib/store/artists/selectors';
import addServerTimingHeader from '~/app/lib/utils/addServerTimingHeader';
import { fetchItemByPathAction } from '~/app/lib/store/paths/actions';
import { useAppLoading } from '~/app/components/NextApp/lib/CoreUi';
import PagePlaceholder from '~/app/components/PagePlaceholder';
import { SelectedArtist } from '~/app/lib/store/artists/types';
import { AppPage } from '~/app/components/NextApp/types';
import { toDecodedItemPath } from '~/app/lib/router2';
import ArtistPage from '~/app/pages/ItemPageArtist';
import { useSelector } from '~/app/lib/store/redux';
import { useI18n, withI18n } from '~/app/lib/i18n';
import ApiError from '~/app/lib/errors/ApiError';
import { reportError } from '~/app/lib/sentry';
import { State } from '~/app/lib/store/types';

import {
  selectPathIsLoading,
  selectPathValue,
  selectPathError,
  selectItemByPathGlobal,
} from '~/app/lib/store/paths/selectors';

interface ArtistPageProps {
  artistPath: string;
}

const ArtistNextPage: AppPage<ArtistPageProps> = ({ artistPath }) => {
  const { t } = useI18n('app');

  const pathIsLoading = useSelector((state: State) =>
    selectPathIsLoading(state, artistPath)
  );

  // show the app global loading bar while path is unresolved
  useAppLoading()(pathIsLoading);

  const pathValue = useSelector((state: State) =>
    selectPathValue(state, artistPath)
  );

  const pathError = useSelector((state: State) =>
    selectPathError(state, artistPath)
  );

  const artistId = pathValue && pathValue.id;

  if (!pathValue) {
    return (
      <PagePlaceholder
        isLoading={pathIsLoading}
        error={pathError}
        toErrorText={({ status }) => {
          if (status === 404) {
            return t('errors.item');
          }
        }}
      />
    );
  }

  return <ArtistPage artistId={artistId!} />;
};

ArtistNextPage.getInitialProps = async (ctx): Promise<ArtistPageProps> => {
  const {
    res,
    query,
    reduxStore: { getState, dispatch },
    asPath,
    pathname,
  } = ctx;

  const artistPath = toDecodedItemPath(query);

  const pendingFetch = dispatch(
    fetchItemByPathAction({
      path: artistPath,

      // Only throw errors on serverside to trigger try-catch below.
      // When clientside rendering we async select errors from the redux store.
      silent: !res,

      shouldRefetch: ({ state, existingItem }) => {
        const isNested = selectArtistStateItem(state, existingItem.id)
          ?.isPartial;

        // When the artist in the store is from a 'nested' context
        // we force a refetch to ensure that we get the `artist.albums[]` too.
        // Without them we won't be able to render the releases section.
        return !!isNested;
      },
    })
  );

  // if is server
  if (!process.browser) {
    const timingStart = Date.now();

    const {
      serverRedirectIfNeeded,
    } = require('~/app/lib/router2/redirectIfNeeded/serverRedirectIfNeeded');

    if (res) {
      try {
        // SSR: block render until data fetched and report errors
        const result = await pendingFetch;

        if (result) {
          res.setHeader(
            'x-songwhip-resolve-path-from-api-cache',
            result.fromApiCache ? 'true' : 'false'
          );
        }
      } catch (error) {
        await onServerFetchError({
          error,
          ctx,
        });

        return {
          artistPath,
        };
      }

      const state = getState();

      const artist = selectItemByPathGlobal(
        state,
        artistPath
      ) as SelectedArtist;

      addServerTimingHeader(
        res,
        'songwhip-web-ssr-resolve-path',
        Date.now() - timingStart
      );

      serverRedirectIfNeeded({
        res,
        state,
        expectedPagePath: artist.pagePath,
        currentRoutePathname: pathname,
        currentAsPath: asPath!,
        pageOwnedByAccountIds: artist.ownedByAccountIds,
      });
    }
  }

  return {
    artistPath,
  };
};

const onServerFetchError = async ({
  error,
  ctx,
}: {
  error: ApiError;
  ctx: NextPageContext;
}) => {
  const { res } = ctx;

  // add the correct status-code, this is super important as returning
  // a non-error code means that the response will be cached upstream
  if (res) {
    res.statusCode = error.status || 500;
  }

  // don't report 404s, way too noisy
  if (error.status === 404) {
    return;
  }

  await reportError({
    error,
    nextCtx: ctx,
    extras: {
      caughtAt: 'pages/[param1]:onServerFetchError',
    },
  });
};

const ArtistNextPageWithI18n = withI18n(ArtistNextPage, ['item']);

export default ArtistNextPageWithI18n;
