import { FC } from 'react';
import { usePageTheme } from '~/app/components/ItemPage/ItemPageEdit/addons/theme/PageThemeContext';

import Text from '~/app/components/Text';
import { toRgba } from '~/app/lib/utils/color';

const IS_LONG_THRESHOLD = 22;

export const ArtistTitle: FC<{ text: string; padding?: string }> = ({
  text,
  children,
  ...textProps
}) => {
  const textIsLong = text!.length > IS_LONG_THRESHOLD;
  const minFontSize = textIsLong ? '3.57rem' : '4.2rem';
  const maxFontSize = textIsLong ? '4.6rem' : '4.9rem';
  const baseSize = textIsLong ? '5vw' : '7vw';
  const isAllCaps = text.toUpperCase() === text;
  const pageTheme = usePageTheme();

  return (
    <Text
      size={baseSize}
      minSize={minFontSize}
      maxSize={maxFontSize}
      maxWidth="14em"
      isCentered
      {...textProps}
    >
      <Text
        testId="title"
        tag="h1"
        bold
        centered
        size="inherit"
        // Allow up to three lines for long artist names. This
        // might look bad but it's wrose to truncate the artist name.
        lineClamp={3}
        lineHeight={1.05}
        padding="0 1rem 0.1em"
        shadow={`${toRgba(pageTheme.backgroundColor, 0.1)} 0 0 .05em,${toRgba(
          pageTheme.backgroundColor,
          0.2
        )} 0 .01em .1em`}
        opacity={0.9}
        style={{
          marginTop: '-.2em',
          marginBottom: isAllCaps ? '-.05em' : '-0.02em',

          // we not using prettyWrap() here as it was causing bad wrapping for some long artist names
          // @ts-ignore
          textWrap: 'balance',
        }}
      >
        {text}
      </Text>
      {children}
    </Text>
  );
};
