import { RefObject, useCallback, useEffect, useRef } from 'react';

import { on } from '~/app/lib/utils/events';
import onTrackpadSwipe from './onTrackpadSwipe';

enum Direction {
  LEFT,
  RIGHT,
}

export interface CarouselApi {
  snapToIndex: (index: number) => void;
}

const useCarousel = ({
  listElRef,
  rootElRef,
  onChange = () => {},
  totalItems,
}: {
  rootElRef: RefObject<HTMLElement>;
  listElRef: RefObject<HTMLElement>;
  onChange?: (index: number) => void;
  totalItems: number;
}): CarouselApi => {
  const currentIndexRef = useRef(0);
  const isDraggingRef = useRef(false);

  const snapToIndex = useCallback(
    (index: number) => {
      const rootEl = rootElRef.current;
      const listEl = listElRef.current;

      if (!rootEl || !listEl) return;

      // clamp value
      index = Math.min(Math.max(index, 0), totalItems - 1);

      const { width: rootElWidth } = rootEl.getBoundingClientRect();
      const translateX = -(index * rootElWidth);

      setListXOffset(translateX);
      listEl.style.transition = 'transform 240ms';

      currentIndexRef.current = index;

      onChange(index);
    },
    [totalItems]
  );

  const setListXOffset = (x: number) => {
    const listEl = listElRef.current;
    if (!listEl) return;

    listEl.style.transition = '';
    listEl.style.transform = `translateX(${Math.round(x)}px)`;
  };

  const snapToClosestItem = ({ direction }: { direction: Direction }) => {
    const rootEl = rootElRef.current;
    const listEl = listElRef.current;

    if (!rootEl || !listEl) return;

    const { left: rootElX, width: rootElWidth } =
      rootEl.getBoundingClientRect();

    const { left: listElX } = listEl.getBoundingClientRect();
    let listElRelativeX = listElX - rootElX;

    if (direction === Direction.LEFT) {
      listElRelativeX -= rootElWidth * 0.5;
    } else if (direction === Direction.RIGHT) {
      listElRelativeX += rootElWidth * 0.5;
    }

    const closestItemIndex = Math.min(
      Math.round(Math.abs(Math.min(listElRelativeX, 0)) / rootElWidth),
      totalItems - 1
    );

    snapToIndex(closestItemIndex);
  };

  useEffect(() => {
    const listEl = listElRef.current;
    const rootEl = rootElRef.current;

    if (!listEl || !rootEl) {
      return;
    }

    const offTouchMove = on(listEl, 'touchmove', (event) => {
      if (isDraggingRef.current) {
        event.preventDefault();
      }
    });

    // move carousel back/forward when desktop trackpad is swiped
    onTrackpadSwipe({
      el: rootEl,

      onSwipeBack() {
        snapToIndex(currentIndexRef.current - 1);
      },

      onSwipeForward() {
        snapToIndex(currentIndexRef.current + 1);
      },
    });

    const offPointerDown = on(rootEl, 'pointerdown', (event: PointerEvent) => {
      const startX = event.clientX;
      let direction: Direction;
      let prevX: number;

      const isLeftButton = event.button === 0;
      if (!isLeftButton) return;

      const { left: listElStartX } = listEl.getBoundingClientRect();
      const { left: rootElX } = rootEl.getBoundingClientRect();

      const onMove = (event: PointerEvent) => {
        event.preventDefault();

        const x = event.clientX;
        const deltaX = x - startX;
        direction = prevX < x ? Direction.RIGHT : Direction.LEFT;

        if (!isDraggingRef.current && Math.abs(deltaX) > 5) {
          // Show the grabbing hand cursor. Disable pointer events here on content
          // to ensure any `cursor` styling lower down doesn't override this styling.
          // Disabling pointer-events here also prevents any click handlers from
          // firing on content inside the carousel.
          listEl.style.pointerEvents = 'none';
          rootEl.style.cursor = 'grabbing';

          isDraggingRef.current = true;
        }

        if (isDraggingRef.current) {
          const translateX = listElStartX - rootElX + deltaX;
          setListXOffset(translateX);
        }

        prevX = x;
      };

      const onEnd = () => {
        setTimeout(() => {
          if (isDraggingRef.current) {
            snapToClosestItem({ direction });
            isDraggingRef.current = false;
          }

          removeEventListener('pointermove', onMove);
          removeEventListener('pointerup', onEnd);
          removeEventListener('pointercancel', onEnd);

          // re-enable pointer-events and disable 'grab' cursor styling
          listEl.style.pointerEvents = '';
          rootEl.style.cursor = '';
        });
      };

      addEventListener('pointermove', onMove);
      addEventListener('pointerup', onEnd);
      addEventListener('pointercancel', onEnd);
    });

    return () => {
      offTouchMove();
      offPointerDown();
    };
  }, []);

  return {
    snapToIndex,
  };
};

export default useCarousel;
