import withSpacing, { WithSpacingProps } from '~/app/lib/hocs/withSpacing';
import debounce from '~/app/lib/utils/debounce';

/**
 * This inline script is inserted at the top of the <head>. If used inline it
 * will trigged a style-recalc which can cause flicker and perf issues.
 */
export const VH_INLINE_SCRIPT = `document.documentElement.style.setProperty('--vh',(innerHeight * 0.01)+'px');`;
interface VhProps extends WithSpacingProps {
  value?: number;
}

// value is kept up to date on resize
if (process.browser) {
  const updateDom = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  addEventListener('resize', debounce(updateDom, 100));
}

export const toVhValue = (vh: number | undefined, modifier = '') => {
  return vh ? `calc((${vh} * var(--vh, 1vh))${modifier})` : '';
};

const Vh = withSpacing<VhProps>(
  ({ className = '', style, value, children }) => {
    return (
      <div
        style={{
          height: toVhValue(value),
          ...style,
        }}
        className={`${className} vh`}
      >
        {/* fallback for browsers w/o css vars */}
        <style jsx>{`
          .vh {
            height: ${value}vh;
          }
        `}</style>
        {children}
      </div>
    );
  }
);

export default Vh;
