import { FC, RefObject, useRef, useEffect, useImperativeHandle } from 'react';
import Clickable from '~/app/components/Clickable';
import ChevronIcon from '~/app/components/Icon/ChevronIcon';
import { useScroller } from '~/app/components/Scroller2';
import wait from '~/app/lib/utils/wait';

const ScrollPrompt: FC<{ apiRef: RefObject<() => void> }> = ({ apiRef }) => {
  const isClearedRef = useRef(false);
  const elRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<any>();
  const scroller = useScroller();
  const SHOW_DELAY_MS = 3000;

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      const el = elRef.current;
      if (!el) return;

      const scrollTop = scroller?.getScrollTop() || 0;

      // if the page has been scroller don't show it
      if (scrollTop > 0) {
        return;
      }

      el.firstElementChild!.classList.add('float');
    }, SHOW_DELAY_MS);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useImperativeHandle(apiRef, () => {
    return async () => {
      if (isClearedRef.current) return;
      isClearedRef.current = true;

      const el = elRef.current;
      if (!el) return;

      el.style.opacity = '0';
      await wait(500);
      el.style.display = 'none';
    };
  });

  if (isClearedRef.current) {
    return null;
  }

  return (
    <div
      ref={elRef}
      style={{
        position: 'absolute',
        left: '50%',
        bottom: 0,
        transform: 'translateX(-50%)',
        pointerEvents: 'none',
        transition: 'opacity .2s',
      }}
    >
      <Clickable
        testId="scrollPrompt"
        className="button"
        style={{
          opacity: 0,
        }}
        onClick={() => {
          scroller?.scrollTo(innerHeight * 0.72);
        }}
      >
        <ChevronIcon
          direction="up"
          isCentered
          size="5.5rem"
          fullWidth={false}
          style={{
            filter: 'drop-shadow(0 0.1rem .6rem rgba(0,0,0,0.8))',
          }}
        />
      </Clickable>
      <style jsx>{`
        div :global(.button) {
          pointer-events: none;
        }

        div :global(.button.float) {
          animation: float 2s infinite;
          pointer-events: all;
        }

        @keyframes float {
          0% {
            transform: translateY(90%) scale(0.8);
            opacity: 0;
          }
          10% {
            opacity: 1;
          }
          100% {
            transform: translateY(-70%) scale(1);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default ScrollPrompt;
