import { Dispatch } from 'redux';

import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { localizeArtistApi } from '~/app/lib/songwhipApi/artists';
import { State } from '~/app/lib/store/types';

import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { fetchArtistError, fetchArtistStart } from './fetchArtist';

export default ({ artistId }: { artistId: number }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const country = selectUserCountry(state);

    if (!country) throw new Error('user country undefined');

    dispatch(fetchArtistStart(artistId));

    try {
      const payload = await localizeArtistApi({ artistId, country });
      dispatchFetchItemSuccess(dispatch, payload);
    } catch (error) {
      dispatch(fetchArtistError(artistId, error));
    }
  };
