import { FC, useCallback } from 'react';

import IconLinkButton from '~/app/components/ItemPage/sections/lib/IconLinkButton';
import { useItemContext } from '~/app/components/ItemPage/ItemPageContext';
import { PageSectionComponent } from '~/app/components/ItemPage/sections/types';
import HorizontalScroller from '~/app/components/Scroller2/HorizontalScroller';
import { resolveLinks } from '~/app/components/ItemPage/sections/lib';
import { ArtistTitle } from './components';
import { DEFAULT_LINKS, MAX_ICON_LINKS } from './constants';
import { TitleLinksSectionProps } from './types';

const TitleLinksSection: PageSectionComponent<TitleLinksSectionProps> = ({
  layoutData,
  links = DEFAULT_LINKS,
  text = layoutData.item.name,
}) => {
  if (!text) {
    return null;
  }

  return (
    <div data-testid="titleLinksSection">
      <ArtistTitle text={text}>
        <ArtistTitleIcons links={links} />
      </ArtistTitle>
    </div>
  );
};

const ArtistTitleIcons: FC<{ links: string[] }> = ({ links }) => {
  const layoutData = useItemContext().data;

  const linksResolved = resolveLinks({ layoutData, links });
  // unable to resolve any links from given props
  if (!linksResolved) return null;

  return (
    <HorizontalScroller
      centerContent
      margin="1.25rem 0 -0.5rem"
      gradientColor="mask"
      overflowStyle="outside"
      arrowSize="1.6rem"
      contentStyle={{
        padding: '0 .5rem',
      }}
      renderContent={useCallback(() => {
        return linksResolved.slice(0, MAX_ICON_LINKS).map((resolved) => {
          return (
            <IconLinkButton
              key={resolved.link}
              Icon={resolved.Icon || resolved.service.Icon}
              link={resolved.link}
              name={resolved.text}
              withHoverOpacityFrom={0.95}
              size="3.21rem"
              margin="0 0.19em"
              testId={resolved.serviceType}
            />
          );
        });
      }, [linksResolved])}
    />
  );
};

export default TitleLinksSection;
