import { ServerResponse } from 'http';

const addServerTimingHeader = (
  res: ServerResponse,
  key: string,
  value: number
) => {
  const existingTimings = res.getHeaders()['server-timing'];

  const newTimings =
    typeof existingTimings === 'string'
      ? `${existingTimings}, ${key};dur=${value}`
      : `${key};dur=${value}`;

  res.setHeader('server-timing', newTimings);
};

export default addServerTimingHeader;
