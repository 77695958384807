import { useEffect, useRef, useState, useDebugValue } from 'react';
import onceImageLoaded from './onceImageLoaded';

const useImageLoaded = (imageRef: React.RefObject<HTMLImageElement>) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const hasUnmounted = useRef(false);

  useDebugValue('useImageLoaded');

  useEffect(() => {
    if (!imageRef.current) return;

    onceImageLoaded(imageRef.current).then(() => {
      // abort if component has unmounted before callback
      if (hasUnmounted.current) return;

      setIsLoaded(true);
    });

    return () => {
      hasUnmounted.current = true;
    };
  }, [imageRef.current]);

  return isLoaded;
};

export default useImageLoaded;
