import { useEffect, useRef, useState } from 'react';
import onceDocumentLoaded from '~/app/lib/utils/onceDocumentLoaded';

const useDocumentLoaded = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const hasUnmounted = useRef(false);

  useEffect(() => {
    onceDocumentLoaded().then(() => {
      // abort if component has unmounted before callback
      if (hasUnmounted.current) return;

      setIsLoaded(true);
    });

    return () => {
      hasUnmounted.current = true;
    };
  }, []);

  return isLoaded;
};

export default useDocumentLoaded;
