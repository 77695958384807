import { CSSProperties } from 'react';

export const toCarouselFrameStyle = ({
  backgroundColor,
  borderColor = '#666',
}: {
  backgroundColor: string;
  borderColor?: string;
}): CSSProperties => {
  return {
    // position relative w/ z-index required to
    // fix ios border-radius overflow masking bug
    position: 'relative',
    zIndex: 1,

    borderRadius: '2.2rem',
    overflow: 'hidden',
    border: `solid 1px ${borderColor}`,
    background: backgroundColor,
  };
};
