import React from 'react';

import withSpacing, { WithSpacingProps } from '~/app/lib/hocs/withSpacing';
import useDocumentLoaded from '~/app/lib/hooks/useDocumentLoaded';
import TransitionInOut from '../TransitionInOut';

import useImageLoaded from './useImageLoaded';
import { getAspect } from './utils';

export interface ImageProps extends WithSpacingProps {
  src: string | undefined;
  alt?: string;
  load?: boolean;
  fillParent?: boolean;
  cover?: boolean;
  objectPosition?: string;
  isLazy?: boolean;
  size?: [number, number];
  aspect?: number;
  style?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
  fadeInDuration?: number;
  borderRadius?: string;
  testId?: string;
}

const Image = withSpacing<ImageProps>(
  ({
    src,
    alt,
    isLazy = false,
    load = true,
    size,
    className,
    style,
    imgStyle,
    aspect,
    fillParent,
    objectPosition = '50% 50%',
    fadeInDuration = 1000,
    borderRadius,
    cover,
    testId,
  }) => {
    const imageRef = React.createRef<HTMLImageElement>();
    const imageLoaded = useImageLoaded(imageRef);
    const documentLoaded = useDocumentLoaded();

    const shouldLoad = () => {
      if (!load) return false;

      return documentLoaded;
    };

    const aspectResolved = aspect || getAspect(size);

    // fix fading image in within parent w/ border-radius needs
    // z-index higher than child to prevent corners bleeding out of border
    const rootStyle: React.CSSProperties = {
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      borderRadius,
      ...style,
    };

    if (fillParent) {
      rootStyle.position = 'absolute';
      rootStyle.width = '100%';
      rootStyle.height = '100%';
      rootStyle.left = rootStyle.top = 0;
    }

    const innerStyle: React.CSSProperties = {
      width: '100%',
      paddingBottom: aspectResolved ? `${aspectResolved * 100}%` : '',
    };

    if (cover) {
      innerStyle.width = '100%';
      innerStyle.height = '100%';
      innerStyle.boxSizing = 'border-box';
    }

    // When caller requests no fade-in duration we render the image
    // whether loaded or not, otherwise we have to wait for raf before image
    // is shown. When image is likely to be cached or doesn't have undesirable
    // partially loaded state (eg. svg) then we can render straight away.
    const isVisible = fadeInDuration ? !!src && imageLoaded : true;

    return (
      <div className={className} style={rootStyle}>
        <TransitionInOut
          isVisible={isVisible}
          duration={fadeInDuration}
          style={innerStyle}
        >
          {(() => {
            const style: React.CSSProperties = {
              display: 'block',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition,
            };

            if (aspectResolved) {
              style.position = 'absolute';
              style.top = style.bottom = style.left = style.right = 0;
              style.zIndex = 0;
            }

            return (
              <img
                ref={imageRef}
                src={shouldLoad() ? src : undefined}
                style={{ ...style, ...imgStyle }}
                loading={isLazy ? 'lazy' : undefined}
                draggable={false}
                alt={alt}
                // Avoids being rate-limited when loading third-party images
                // we experienced this when loading users' google profile images
                // https://stackoverflow.com/a/61042200/516629
                referrerPolicy="no-referrer"
                data-testid={testId}
              />
            );
          })()}
        </TransitionInOut>
      </div>
    );
  }
);

export default Image;
