import { FC, RefObject, useCallback, useImperativeHandle, useRef } from 'react';

import Clickable, { ClickableOnClick } from '~/app/components/Clickable';
import Box from '~/app/components/Box';

export interface CarouselDotsApi {
  setIndex: (index: number) => void;
}

const CarouselDots: FC<{
  onItemClick?: (index: number) => void;
  apiRef?: RefObject<CarouselDotsApi>;
  total: number;
}> = ({ apiRef, total, onItemClick = () => {} }) => {
  const SIZE = '.75rem';
  const SPACE_BETWEEN_REMS = 0.22;
  const items: JSX.Element[] = [];
  const currentIndexRef = useRef(0);
  const rootElRef = useRef<HTMLElement>(null);

  const setIndex = (newIndex: number) => {
    const els = rootElRef.current?.querySelectorAll('.dot');

    [].forEach.call(els, (el, index) => {
      const isActive = index === newIndex;

      el.style.opacity = isActive ? '.8' : '.25';
      el.style.transform = isActive ? '' : 'scale(.89)';
    });

    currentIndexRef.current = newIndex;
  };

  useImperativeHandle(apiRef, () => ({
    setIndex,
  }));

  const onItemClickInternal = useCallback<ClickableOnClick<number>>(
    ({ data: index }) => {
      onItemClick(index);
      setIndex(index);
    },
    [onItemClick]
  );

  for (let i = 0; i < total; i++) {
    const isActive = i === currentIndexRef.current;

    items.push(
      <Clickable
        className="dot"
        testId="carouselDot"
        withActiveStyle={false}
        withHoverStyle={false}
        onClick={onItemClickInternal}
        data={i}
        style={{
          background: '#fff',
          width: SIZE,
          height: SIZE,
          borderRadius: '50%',
          margin: `0 ${SPACE_BETWEEN_REMS}rem`,
          opacity: isActive ? 0.8 : 0.25,
          transform: isActive ? '' : 'scale(.89)',
        }}
      />
    );
  }

  return (
    <Box
      flexBox
      margin={`0 -${SPACE_BETWEEN_REMS}rem`}
      centerContent
      alignCenter
      nodeRef={rootElRef}
    >
      {items}
    </Box>
  );
};

export default CarouselDots;
