const onceImageLoaded = (image: HTMLImageElement) =>
  new Promise<void>((resolve) => {
    if (image.complete && image.src) {
      return resolve();
    }

    image.addEventListener('load', () => {
      setTimeout(resolve, 50);
    });
  });

export default onceImageLoaded;
