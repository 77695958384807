export const DEFAULT_LINKS = [
  '$.item.links.spotify',
  '$.item.links.itunes',
  '$.item.links.youtube',
  '$.item.links.instagram',
  '$.item.links.twitter',
  '$.item.links.facebook',
  '$.item.links.deezer',
  '$.item.links.amazonMusic',
  '$.item.links.tiktok',
  '$.item.links.tidal',
  '$.item.links.snapchat',
  '$.item.links.soundcloud',
];

export const MAX_ICON_LINKS = 9;
