import Debug from 'debug';

import { on } from '~/app/lib/utils/events';

const debug = Debug('songwhip/onTrackPadSwipe');
const SWIPE_THRESHOLD_PX = 50;

const onTrackpadSwipe = ({
  el,
  onSwipeBack,
  onSwipeForward,
}: {
  el: HTMLElement;
  onSwipeBack: () => void;
  onSwipeForward: () => void;
}) => {
  let wheelEndTimeout: NodeJS.Timeout;
  let isVertical = false;
  let swipeDetected = false;
  let deltaX = 0;
  let deltaY = 0;

  // 'wheel' events are similar to 'scroll' events
  return on(
    el,
    'wheel',
    (event: WheelEvent & { wheelDeltaX?: number; wheelDeltaY?: number }) => {
      debug('on wheel', event.deltaX, event.deltaY, event);

      // each 'wheel' event has a pixel delta for that specific event
      // summing these gives us the overall delta for the gesture/interaction
      deltaX += (event.wheelDeltaX && -event.wheelDeltaX) || event.deltaX;
      deltaY += event.wheelDeltaY || event.deltaY || 0;

      clearTimeout(wheelEndTimeout);

      // debounced to infer when 'wheel' scrolling has ended
      wheelEndTimeout = setTimeout(() => {
        isVertical = false;
        swipeDetected = false;
        deltaX = 0;
        deltaY = 0;
      }, 30);

      // when the wheel event is vertical ignore it to allow scroll
      // gesture on `el` to allow page to scroll vertically
      if (isVertical || (Math.abs(deltaX) < 10 && Math.abs(deltaY) > 2)) {
        isVertical = true;
        return true;
      }

      // prevent chrome back/forward gesture
      event.preventDefault();

      // if a swipe already detected abort
      if (swipeDetected) {
        return;
      }

      if (Math.abs(deltaX) > SWIPE_THRESHOLD_PX) {
        debug('swipe detected');
        swipeDetected = true;

        if (deltaX < 0) {
          onSwipeBack();
        } else {
          onSwipeForward();
        }
      }
    },
    {
      // we event can't be passive if we are to interrupt
      // the native swipe back/forward gesture
      passive: false,
    }
  );
};

export default onTrackpadSwipe;
