import { Dispatch } from 'redux';
import Debug from 'debug';

import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { refreshArtistApi } from '~/app/lib/songwhipApi/artists';
import { State } from '~/app/lib/store/types';

import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { fetchArtistError, fetchArtistStart } from './fetchArtist';

const debug = Debug('songwhip/store/artists/actions');

export default ({ artistId }: { artistId: number }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    debug('refresh artist: %s', artistId);

    if (!artistId) throw new Error('`artistId` required');

    dispatch(fetchArtistStart(artistId));

    try {
      const country = selectUserCountry(getState());
      const payload = await refreshArtistApi({ artistId, country });

      dispatchFetchItemSuccess(dispatch, payload);
    } catch (e) {
      const error = e as Error;
      dispatch(fetchArtistError(artistId, error));
    }
  };
