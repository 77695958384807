import { useEffect, useMemo } from 'react';

import localizeArtist from '~/app/lib/store/artists/actions/localizeArtist';
import refreshArtist from '~/app/lib/store/artists/actions/refreshArtist';
import fetchArtist from '~/app/lib/store/artists/actions/fetchArtist';
import { selectUserIsBot } from '~/app/lib/store/session/selectors';
import { useAppLoading } from '~/app/components/NextApp/lib/CoreUi';
import { useSelector, useDispatch } from '~/app/lib/store/redux';
import { SelectedArtist } from '~/app/lib/store/artists/types';
import { useAppRouter } from '~/app/lib/router2';

/**
 * A react hook that takes care of common item page checks
 *
 * WARN: this artistId can change and the refs won't update
 * we need a way of resetting refs when artistId changes
 */
const useArtistChecks = (artist?: SelectedArtist) => {
  const userIsBot = useSelector(selectUserIsBot);
  const router = useAppRouter();
  const dispatch = useDispatch();

  const actionState = useMemo(
    () => ({
      didRefresh: false,
      didLocalize: false,
      didFetch: false,
    }),
    [artist?.id]
  );

  // never let users view the various-artists page as it's a faux placeholder record
  useEffect(() => {
    if (!artist?.isVariousArtists) return;
    router.replace('/');
  }, [artist?.isVariousArtists]);

  // show the app loading indicator whenever the album is in a 'loading' state
  useAppLoading()(artist?.isLoading);

  useMemo(() => {
    // only run this block in browser
    if (!process.browser) return;

    if (!artist || artist.isLoading) return;

    const needsLocalize = !artist.hasLinksForUserCountry;
    const needsRefresh = artist.isStale;
    const needsFetch = artist.isShallow;

    if (needsFetch && !actionState.didFetch) {
      dispatch(fetchArtist({ artistId: artist.id }));
      // flag that fetch was attempted to prevent infinite loop if api request fails
      actionState.didFetch = true;
      return;
    }

    // don't make any needless api requests if user
    // is bot to save api quotas
    if (userIsBot) return;

    if (needsLocalize && !needsRefresh && !actionState.didLocalize) {
      dispatch(localizeArtist({ artistId: artist.id }));
      // flag that localize was attempted to prevent infinite loop if api request fails
      actionState.didLocalize = true;
      return;
    }

    if (needsRefresh && !actionState.didRefresh) {
      dispatch(refreshArtist({ artistId: artist.id }));
      // flag that refresh was attempted to prevent infinite loop if api request fails
      actionState.didRefresh = true;
    }
  }, [artist]);
};

export default useArtistChecks;
