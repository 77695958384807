import toWebPage from '~/app/lib/utils/toStructuredData/toWebPage';
import toArtist from '~/app/lib/utils/toStructuredData/toArtist';
import toImage from '~/app/lib/utils/toStructuredData/toImage';

export default ({
  website,
  toBreadcrumb,
  title,
  description,
  artistName,
  imageUrl,
  pagePath,
  pageUrl,
}) => {
  if (!artistName) return;

  const primaryImage = toImage({ url: imageUrl, title: artistName });

  const breadcrumb = toBreadcrumb([
    {
      name: artistName,
      pagePath,
    },
  ]);

  const webPage = toWebPage({
    url: pageUrl,
    title,
    description,
    primaryImageId: primaryImage && primaryImage['@id'],
    breadcrumbId: breadcrumb['@id'],
    websiteId: website['@id'],
  });

  const artist = toArtist({
    url: pageUrl,
    name: artistName,
    imageId: primaryImage && primaryImage['@id'],
    webPageId: webPage['@id'],
  });

  return [primaryImage, breadcrumb, webPage, artist];
};
